import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const QuestionDE8 = () => {

    return (
        <>
       
        </>
    );
}

export default QuestionDE8;